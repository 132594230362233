//商行管理 -- 商行管理（新增商行）
import React, { PureComponent } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  Form,
  Card,
  Button,
  Input,
  Select,
  message,
  Row,
  Col,
  Radio,
  Modal,
  DatePicker,
} from 'antd';
import { Image } from 'antd-v5';

import PageHeaderLayout from '../../../component/page-header-layout/index';
import { STALL_TYPES, STALL_SALE_TYPE, BUSINESS_TYPE } from '../../../utils/deviceType';

import './style.less';
import { genaralPassword } from '../../../utils/math';
import { executeOnceDecorator } from '../../../utils/ExecuteOnceDecorator';

import { fileSelectList } from '../../../state/action/batch';
import { getAllSale } from '../../../state/action/salesman';
import {
  getOrgList,
  view,
  saveStall,
  updateStall,
  enableMarket,
} from '../../../state/action/stall';
import { colorList, view as stallView } from '../../../state/request/stall';
import { getMarketList } from '../../../state/request/stall';
import { getDeposit } from '@/utils/utils';
import { encrypt } from '@/utils/math';
import { ENCRYPT_KEY } from '@/utils/const';

const FormItem = Form.Item;
const Option = Select.Option;
const defaultServiceEndTime = moment().add(1, 'days').startOf('day').add(30, 'days').valueOf(); //默认的服务时间  当天日期（第2天0点）+30天
const PreviewImage = ({ url, preview = true, style }) => {
  if (!url) return null;
  return (
    <Image.PreviewGroup>
      <Image src={url} style={{ width: 60, ...style }} preview={preview} />
    </Image.PreviewGroup>
  );
};

@connect(
  (state) => {
    return { batch: state.batch, stall: state.stall, salesman: state.salesman };
  },
  {
    fileSelectList,
    getOrgList,
    view,
    saveStall,
    updateStall,
    enableMarket,
    getAllSale,
  },
)
@Form.create()
class NewStallManage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      visible: false, //添加时候的提示用的模态框，
      tooltip: '', //模态框的提示字符
      disabled: false,
      display_name: 'none',
      status: 0,
      loading: false,
      marketData: [],
      colorList: [],
    };
    this.record = this.props.location.state.record;
    this.isEdit = this.record ? true : false;
  }

  async componentDidMount() {
    if (this.isEdit) {
      await this.props.view({ id: this.record.id }); //编辑商行获取商行信息
      let {
        stall: { stallsByIdData },
      } = this.props;
      // 过渡期商行类型的处理
      if (stallsByIdData.type === 3) {
        stallsByIdData.type = 1;
      } else if (stallsByIdData.type === 4) {
        stallsByIdData.type = 2;
      }
      this.setState({ ...stallsByIdData }, () => {
        this.setFormInitialValue();
      });
    } else {
      this.setState(
        {
          id: '',
          marketId: '',
          name: '',
          phone: '',
          shortName: '',
          address: '',
          boothNum: '',
          idNum: '',
          telephone: '',
          countUnit: '',
          weightUnit: '',
          userPhone: '',
          userPassword: '',
          lrName: '',
          qrCodeUrl: '',
          accountNum: '',
          systemType: 1, //1正式商行,2测试商行
          type: 2, //1手机极简版,2专业版,3手机极简版测试,4专业版测试,5基础版,6旗舰版
          employNum: 0, // 员工个数限制
          pactStatus: 0,
          stallRemark: '',
          serviceEndTime: defaultServiceEndTime,
          signSaleId: undefined,
          belongSaleId: undefined,
          saleType: 1,
          renewAmountList: [],
          businessHours: '', //营业时间0-23
          qrCode: '', // 收款二维码
          slogan: '', // 图片广告语
          referralStallId: '', // 推荐商行ID
          referralStallName: '', // 推荐商行名称
        },
        () => {
          this.setFormInitialValue();
        },
      );
    }
    this.getColorList();
    await Promise.all([
      this.props.getOrgList(),
      this.props.getAllSale(), //获取所有的销售人员
    ]);

    const res = await getMarketList({});
    if (res?.code === 200) {
      this.setState({ marketData: res.data || [] });
    }
  }

  getColorList = async () => {
    const res = await colorList({});
    if (res?.code === 200) {
      if (!this.isEdit) {
        let typeColor;
        let findItem = res.data.find((item) => item.type === this.state.type);
        if (findItem) {
          typeColor = findItem.typeColor;
        }
        this.setState({
          typeColor,
        });
      }
      this.setState({ colorList: res.data || [] });
    }
  };

  // 新增档口
  async fillDataSave(params) {
    this.props.saveStall(params).then(() => {
      const {
        stall: { fileStallData },
      } = this.props;
      if (fileStallData && fileStallData.code === 200) {
        this.props.history.push({ pathname: '/booth/stallManager/' });
      } else if (fileStallData && fileStallData.code && fileStallData.code === 6012) {
        this.setState({ visible: true, tooltip: fileStallData.msg });
      } else if (fileStallData && fileStallData.code && fileStallData.code !== 200) {
        this.setState({ disabled: false });
      }
    });
  }

  // 修改档口
  fillDataUpdate(params) {
    this.props.updateStall(params).then(() => {
      const {
        stall: { fileStallData },
      } = this.props;
      if (fileStallData && fileStallData.code === 200) {
        this.toStallMangent();
      } else if (fileStallData && fileStallData.code && fileStallData.code !== 200) {
        this.setState({ disabled: false });
      }
    });
  }

  showConfirm(e) {
    this.handleSubMitItem(e);
  }

  //选择签约BD
  handleChangeBySignBD = (value) => {
    // 修改时候不联动选择
    this.props.form.setFieldsValue({ signSaleId: value });
    if (!this.isEdit) {
      this.props.form.setFieldsValue({ belongSaleId: value });
    }
  };

  toStallMangent() {
    const status = this.props.location.state.status;
    if (status === 'Detail') {
      this.props.history.push({
        pathname: '/booth/stallManager/stallManagementDetail',
        state: { record: this.record },
      });
    } else {
      this.props.history.push({ pathname: '/booth/stallManager/', state: {} });
    }
  }

  handleSubMitItem = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) return;
      this.addOrUpdate(values);
    });
  };

  // 设置form表单初始值
  setFormInitialValue = () => {
    const { businessType = 1, type = 5 } =
      this.props.form.getFieldsValue(['businessType', 'type']) || {};
    if (this.isEdit) {
      const {
        stall: { stallsByIdData = {} },
      } = this.props;
      const { renewAmountList = [] } = stallsByIdData;
      const renewAmount1 = renewAmountList.find((v) => v.renewYear === 1)?.renewAmount;
      const renewAmount2 = renewAmountList.find((v) => v.renewYear === 2)?.renewAmount;
      const renewAmount3 = renewAmountList.find((v) => v.renewYear === 3)?.renewAmount;
      const { renewYear, renewAmount } =
        renewAmountList.find((item) => ![1, 2, 3].includes(item.renewYear)) || {};
      this.props.form.setFieldsValue({
        renewAmount1,
        renewAmount2,
        renewAmount3,
        renewYear,
        activityRenewAmount: renewAmount,
      });
    } else {
      this.props.form.setFieldsValue({
        renewAmount1: getDeposit(businessType, type, 1),
        renewAmount2: getDeposit(businessType, type, 2),
        renewAmount3: getDeposit(businessType, type, 3),
        activityRenewAmount: '',
      });
    }
  };

  // 新增商行切换商行类型，修改默认价格
  changeType = (e, key) => {
    const { businessType, type } = this.props.form.getFieldsValue(['type', 'businessType']);
    const _type = key === 'type' ? e.target.value : type;
    const _businessType = key === 'businessType' ? e.target.value : businessType;
    if (!this.isEdit) {
      this.props.form.setFieldsValue({
        renewAmount1: getDeposit(_businessType, _type, 1),
        renewAmount2: getDeposit(_businessType, _type, 2),
        renewAmount3: getDeposit(_businessType, _type, 3),
      });
    }
    this.props.form.setFieldsValue({
      typeColor: this.state.colorList.find((item) => item.type === _type)?.typeColor,
    });
  };

  @executeOnceDecorator(3000)
  addOrUpdate(values) {
    const {
      marketId,
      businessName,
      boothPhone,
      businessline,
      address,
      boothNum,
      idNum,
      telephone,
      countUnit,
      weightUnit,
      bankNum,
      bankPwd,
      lrName,
      qrCodeUrl,
      accountNum,
      pactStatus,
      systemType,
      type,
      businessType,
      employNum,
      stallRemark,
      serviceEndTime,
      signSaleId,
      belongSaleId,
      saleType,
      renewAmount1,
      renewAmount2,
      renewAmount3,
      activityRenewAmount,
      renewYear,
      businessHours,
      qrCode,
      slogan,
      referralStallId,
      typeColor,
    } = values;

    const renewAmountList = [
      { renewYear: 1, renewAmount: renewAmount1 },
      ...(renewAmount2 ? [{ renewYear: 2, renewAmount: renewAmount2 }] : []),
      ...(renewAmount3 ? [{ renewYear: 3, renewAmount: renewAmount3 }] : []),
      ...(activityRenewAmount ? [{ renewYear, renewAmount: activityRenewAmount }] : []),
    ];

    const params = {
      name: businessName,
      phone: boothPhone,
      shortName: businessline,
      address: address,
      boothNum: boothNum,
      idNum: idNum,
      telephone: telephone,
      countUnit: countUnit,
      weightUnit: weightUnit,
      accountNum: accountNum,
      systemType: systemType,
      type: type,
      businessType,
      employNum: employNum,
      pactStatus: pactStatus,
      pactStartTime: null,
      stallRemark: stallRemark,
      serviceEndTime: moment(serviceEndTime, 'YYYY-MM-DD HH:mm:ss').valueOf(),
      signSaleId,
      belongSaleId,
      qrCodeUrl: qrCodeUrl,
      renewAmountList,
      businessHours: businessHours,
      qrCode: qrCode,
      slogan: slogan,
      referralStallId: referralStallId || 0,
      typeColor,
    };
    const year = renewYear === undefined || renewYear === null ? 0 : renewYear;

    if ((activityRenewAmount && !year) || (!activityRenewAmount && year)) {
      message.warn('多年续费金额与年限必须同时填写');
      return;
    }

    const addParams = {
      ...params,
      marketId: marketId,
      userPhone: bankNum,
      userPassword: encrypt(ENCRYPT_KEY, bankPwd),
      lrName: lrName,
      saleType,
    };

    const updateParams = {
      ...params,
      id: this.state.id,
      marketId: marketId,
    };
    //修改档口
    if (this.isEdit) {
      this.fillDataUpdate(updateParams);
    } else {
      //新增档口
      this.fillDataSave(addParams);
    }
  }

  //取消模态框
  handleCannel = () => {
    this.setState({ visible: false });
  };

  // 商行类型:新增无手机极简版,修改禁用手机极简版
  getStallTypeRadio(isEdit, type) {
    if (isEdit && type === 1) {
      return (
        <Radio.Group>
          <Radio value={1}>手机极简版</Radio>
        </Radio.Group>
      );
    } else {
      // 新增商行或修改非手机极简版商行
      return (
        <Radio.Group onChange={(e) => this.changeType(e, 'type')}>
          <Radio value={8}>便捷版</Radio>
          <Radio value={5}>基础版</Radio>
          <Radio value={7}>标准版</Radio>
          <Radio value={2}>专业版</Radio>
          <Radio value={6}>旗舰版</Radio>
        </Radio.Group>
      );
    }
  }

  // 查询推荐商行名称
  async getReferralStallName() {
    this.props.form.validateFields(['referralStallId'], async (errors, values) => {
      if (errors) {
        this.setState({ referralStallName: '--' });
        return;
      }
      const { referralStallId } = values;
      const res = await stallView({ id: referralStallId });
      const { id, name } = res.data || {};
      if (!id) {
        message.info('未查询到该推荐商行');
        this.setState({ referralStallName: '--' });
      } else {
        this.setState({ referralStallName: name });
      }
    });
  }

  render() {
    let {
      salesman: { getAllSale = [] },
    } = this.props;
    const { marketData = [] } = this.state;
    if (this.isEdit) {
      //解决编辑被禁用的市场时，市场控件没显示值的问题
      const arrMarket = marketData.filter((v) => v.id === this.record.marketId); //判断是否有市场
      if (arrMarket && arrMarket.length === 0) {
        marketData.push({ id: this.record.marketId, name: this.record.marketName });
      }
    }

    let {
      marketId,
      name,
      phone,
      shortName,
      address,
      boothNum,
      idNum,
      telephone,
      countUnit,
      weightUnit,
      userPhone,
      userPassword,
      lrName,
      qrCodeUrl,
      accountNum,
      systemType,
      type,
      businessType = 1,
      employNum,
      pactStatus,
      stallRemark,
      serviceEndTime = defaultServiceEndTime,
      signSaleId,
      saleType,
      businessHours,
      qrCode,
      slogan,
      referralStallId,
      referralStallName,
      renewYear,
      typeColor,
      colorList,
    } = this.state;
    const { getFieldDecorator, getFieldsValue } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    };

    const year = getFieldsValue(['renewYear']);

    const extraContent = (
      <div className='extraContent' style={{ width: '100%' }}>
        <Form onSubmit={this.showConfirm.bind(this)}>
          <Card title='基本信息' style={{ paddingBottom: 60 }}>
            <Row>
              <Col span={12} style={{ paddingTop: '10px' }}>
                <FormItem {...formItemLayout} label='选择市场'>
                  {getFieldDecorator('marketId', {
                    initialValue: marketId ? marketId : undefined,
                    rules: [
                      {
                        required: true,
                        message: '请选择市场!',
                      },
                    ],
                  })(
                    <Select
                      placeholder='请选择市场'
                      style={{ minWidth: '100%' }}
                      showSearch
                      allowClear
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {marketData.map((item) => (
                        <Option key={item.id} value={item.id}>{`${item.name}(${item.id})`}</Option>
                      ))}
                    </Select>,
                  )}
                </FormItem>
              </Col>
              <Col span={12} style={{ paddingTop: '10px' }}>
                <FormItem {...formItemLayout} label='商行名称'>
                  {getFieldDecorator('businessName', {
                    initialValue: name,
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: ' 请输入商行名称!',
                      },
                    ],
                  })(<Input placeholder='请输入商行名称' />)}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span={12} style={{ paddingTop: '10px' }}>
                <FormItem {...formItemLayout} label='联系电话'>
                  {getFieldDecorator('boothPhone', {
                    initialValue: phone,
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        pattern: /^((0\d{2,3}-\d{8})|(0\d{2,3}\d{8})|((1[0-9]))\d{9})$/,
                        message: '请输入正确的电话号码!',
                      },
                    ],
                  })(<Input placeholder='请输入联系电话' />)}
                </FormItem>
              </Col>
              <Col span={12} style={{ paddingTop: '10px' }}>
                <FormItem {...formItemLayout} label='商行简称'>
                  {getFieldDecorator('businessline', {
                    initialValue: shortName,
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: ' 请输入商行简称!',
                      },
                    ],
                  })(<Input placeholder='请输入商行简称' />)}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span={12} style={{ paddingTop: '10px' }}>
                <FormItem {...formItemLayout} label='商行地址'>
                  {getFieldDecorator('address', {
                    initialValue: address,
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: ' 请输入商行地址!',
                      },
                    ],
                  })(<Input.TextArea rows={4} placeholder='请输入商行地址' />)}
                </FormItem>
              </Col>
              <Col span={12} style={{ paddingTop: '10px' }}>
                <FormItem {...formItemLayout} label='联系方式'>
                  {getFieldDecorator('telephone', {
                    initialValue: telephone,
                    rules: [
                      {
                        required: false,
                        whitespace: true,
                        message: ' 请输入联系方式!',
                      },
                    ],
                  })(<Input.TextArea rows={4} placeholder='请输入联系方式' />)}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span={12} style={{ paddingTop: '10px' }}>
                <FormItem {...formItemLayout} label='证照号码'>
                  {getFieldDecorator('idNum', {
                    initialValue: idNum,
                    rules: [
                      {
                        required: false,
                        whitespace: true,
                        message: ' 请输入证照号码!',
                      },
                    ],
                  })(<Input placeholder='请输入证照号码' />)}
                </FormItem>
              </Col>
              <Col span={12} style={{ paddingTop: '10px' }}>
                <FormItem {...formItemLayout} label='档口数'>
                  {getFieldDecorator('boothNum', {
                    initialValue: boothNum,
                    rules: [
                      {
                        required: false,
                        message: ' 请输入档口数!',
                      },
                    ],
                  })(<Input placeholder='请输入档口数' maxLength={10} />)}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span={12} style={{ paddingTop: '10px' }}>
                <FormItem {...formItemLayout} label='单位库预设单位'>
                  &nbsp;&nbsp;计数：
                  {getFieldDecorator('countUnit', {
                    initialValue: countUnit || '件',
                  })(
                    <Radio.Group>
                      <Radio value='件'>件</Radio>
                    </Radio.Group>,
                  )}
                </FormItem>
                <FormItem {...formItemLayout} label='&nbsp;' colon={false}>
                  &nbsp;&nbsp;计重：
                  {getFieldDecorator('weightUnit', {
                    initialValue: weightUnit || '斤',
                  })(
                    <Radio.Group>
                      <Radio value='斤'>斤</Radio>
                      <Radio value='公斤'>公斤</Radio>
                    </Radio.Group>,
                  )}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label='POS同时在线数' {...formItemLayout}>
                  {getFieldDecorator('accountNum', {
                    initialValue: accountNum,
                    rules: [
                      {
                        required: false,
                        whitespace: false,
                        pattern: /^(\d{0,10})$/,
                        message: '请输入大于等于0的整数',
                      },
                    ],
                  })(<Input placeholder='请输入POS同时在线数' maxLength={10} />)}
                </FormItem>
                <FormItem label='备注' {...formItemLayout}>
                  {getFieldDecorator('stallRemark', {
                    initialValue: stallRemark,
                  })(<Input placeholder='请输入备注' maxLength={50} />)}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span={12} style={{ paddingTop: '10px' }}>
                <FormItem {...formItemLayout} label='选择服务有效时间'>
                  {getFieldDecorator('serviceEndTime', {
                    initialValue: moment(serviceEndTime),
                  })(<DatePicker disabled={this.isEdit ? true : false} />)}
                </FormItem>
              </Col>
              <Col span={12} style={{ paddingTop: '10px' }}>
                <FormItem {...formItemLayout} label='签约BD'>
                  {getFieldDecorator('signSaleId', {
                    initialValue: signSaleId,
                    rules: [{ required: true, message: '请输入签约BD人员名称' }],
                  })(
                    <Select
                      placeholder='请选择签约BD'
                      allowClear
                      onChange={this.handleChangeBySignBD}
                      showSearch
                      filterOption={(input, option) => {
                        return (
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                    >
                      {getAllSale.map((v) => (
                        <Select.Option value={v.id} key={v.id}>
                          {v.name}
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row>
              {this.isEdit ? null : (
                <Col span={12} style={{ paddingTop: '10px' }}>
                  <FormItem {...formItemLayout} label='管理模式' className='form-item'>
                    {getFieldDecorator('saleType', {
                      initialValue: saleType || 1,
                      rules: [{ required: false, message: '请选择管理模式' }],
                    })(
                      <Radio.Group style={{ position: 'absolute', top: 8 }}>
                        {[...STALL_SALE_TYPE].map(([k, v]) => (
                          <Radio style={{ display: 'block' }} value={k} key={k}>
                            {v}
                            {k === 4 || k === 5 ? (
                              <span style={{ color: 'red' }}>（新增）</span>
                            ) : null}
                          </Radio>
                        ))}
                      </Radio.Group>,
                    )}
                  </FormItem>
                </Col>
              )}
            </Row>
            <Row>
              <Col span={12} style={{ paddingTop: '10px' }}>
                <FormItem {...formItemLayout} label='营业时间'>
                  {getFieldDecorator('businessHours', {
                    initialValue: businessHours,
                    rules: [
                      {
                        required: false,
                        whitespace: false,
                        pattern: /^(2[0-3]|[0-1]?\d)$/,
                        message: '请输入0-23的整数',
                      },
                    ],
                  })(<Input placeholder='请输入0-23的整数' maxLength={2} />)}
                </FormItem>
              </Col>
            </Row>
          </Card>
          {this.isEdit ? null : (
            <Card title='主账号信息'>
              <Row>
                <Col span={12}>
                  <FormItem label='主账号' {...formItemLayout}>
                    {getFieldDecorator('bankNum', {
                      initialValue: userPhone,
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          pattern: /^1[3456789]\d{9}$/,
                          message: '请输入正确的手机号码!',
                        },
                      ],
                    })(
                      <Input
                        type='text'
                        autoComplete='off'
                        placeholder='请输入主账号手机号码'
                        disabled={this.isEdit}
                      />,
                    )}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label='账号密码' {...formItemLayout}>
                    {getFieldDecorator('bankPwd', {
                      initialValue: userPassword,
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          message: ' 请输入账号密码!',
                        },
                      ],
                    })(
                      <Input
                        type='password'
                        autoComplete='new-password'
                        placeholder='请输入账号密码'
                        disabled={this.isEdit}
                      />,
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <FormItem label='主账号姓名' {...formItemLayout}>
                    {getFieldDecorator('lrName', {
                      initialValue: lrName,
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          message: ' 请输入主账号用户名字!',
                        },
                      ],
                    })(<Input placeholder='请输入主账号用户名字' disabled={this.isEdit} />)}
                  </FormItem>
                </Col>
              </Row>
            </Card>
          )}
          <Card title='副屏二维码'>
            <Row>
              <Col span={24}>
                <FormItem label='副屏二维码' {...formItemLayout}>
                  {getFieldDecorator('qrCodeUrl', {
                    initialValue: qrCodeUrl,
                    rules: [
                      {
                        required: false,
                        whitespace: true,
                        message: ' 请输入副屏二维码!',
                      },
                    ],
                  })(<Input placeholder='请输入副屏二维码' />)}
                </FormItem>
              </Col>
            </Row>
          </Card>
          <Card title='单据收款码'>
            <Row>
              <Col span={24}>
                <FormItem label='二维码链接' {...formItemLayout}>
                  {getFieldDecorator('qrCode', {
                    initialValue: qrCode,
                    rules: [
                      {
                        required: false,
                        whitespace: true,
                        message: ' 请输入二维码链接!',
                      },
                    ],
                  })(<Input placeholder='请输入二维码链接' />)}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <FormItem label='图片广告语' {...formItemLayout}>
                  {getFieldDecorator('slogan', {
                    initialValue: slogan,
                    rules: [
                      {
                        required: false,
                        whitespace: true,
                        message: '请输入图片广告语',
                      },
                    ],
                  })(<Input placeholder='请输入图片广告语' />)}
                </FormItem>
              </Col>
            </Row>
          </Card>
          <Card title='推荐商行'>
            <Row>
              <Col span={24}>
                <FormItem label='推荐商行ID' {...formItemLayout}>
                  {getFieldDecorator('referralStallId', {
                    initialValue: String(referralStallId || ''),
                    rules: [
                      {
                        required: false,
                        whitespace: true,
                        message: ' 请输入推荐商行ID!',
                      },
                      {
                        pattern: /^\d+$/,
                        message: '只能输入数字!',
                      },
                    ],
                  })(
                    <Input
                      placeholder='请输入推荐商行ID'
                      onBlur={() => this.getReferralStallName()}
                    />,
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <FormItem label='推荐商行' {...formItemLayout}>
                {referralStallName || '--'}
              </FormItem>
            </Row>
          </Card>
          <Card title='系统类型'>
            <Row>
              <Col span={24}>
                <FormItem label='系统类型' {...formItemLayout}>
                  {getFieldDecorator('systemType', {
                    initialValue: systemType,
                  })(
                    <Radio.Group>
                      <Radio value={1}>正式商行</Radio>
                      <Radio value={2}>测试商行</Radio>
                    </Radio.Group>,
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <FormItem label='商行版本' {...formItemLayout}>
                  {getFieldDecorator('type', {
                    initialValue: type,
                  })(this.getStallTypeRadio(this.isEdit, type))}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <FormItem label='版本标识' {...formItemLayout}>
                  {getFieldDecorator('typeColor', {
                    initialValue: typeColor,
                    rules: [
                      {
                        required: true,
                      },
                    ],
                  })(
                    <Select style={{ width: 150, height: 50 }} className='typeColor'>
                      {colorList.map((item) => (
                        <Option key={item.typeColor} value={item.typeColor}>
                          <PreviewImage url={item.displayUrl} preview={false} />
                        </Option>
                      ))}
                    </Select>,
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <FormItem label='商行类型' {...formItemLayout}>
                  {getFieldDecorator('businessType', {
                    initialValue: businessType,
                  })(
                    <Radio.Group onChange={(e) => this.changeType(e, 'businessType')}>
                      {Object.keys(BUSINESS_TYPE).map((item) => (
                        <Radio value={Number(item)}>{BUSINESS_TYPE[item]}</Radio>
                      ))}
                    </Radio.Group>,
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <FormItem label='账号数限制(个)' {...formItemLayout}>
                  {getFieldDecorator('employNum', {
                    initialValue: employNum,
                    rules: [
                      {
                        required: false,
                        whitespace: false,
                        pattern: /^(\d{0,10})$/,
                        message: '请输入大于等于0的整数',
                      },
                    ],
                  })(<Input placeholder='请输入账号数限制' maxLength={5} />)}
                </FormItem>
              </Col>
            </Row>
          </Card>
          <Card title='续费信息'>
            <Row>
              <Col span={24}>
                <div className='row'>
                  <FormItem {...formItemLayout} label='续费年限' colon={false} required={true}>
                    <div>1年</div>
                  </FormItem>
                  <FormItem {...formItemLayout} label='续费金额'>
                    {getFieldDecorator('renewAmount1', {
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          pattern: /^(\d{0,5}(\.\d{1,2})?)$/,
                          message: '请输入正确的续费金额,只能输入数字',
                        },
                      ],
                    })(<Input placeholder='请输入续费金额' min={0} maxLength={9} suffix='元' />)}
                  </FormItem>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div className='row'>
                  <FormItem {...formItemLayout} label='续费年限' colon={false}>
                    <div>2年</div>
                  </FormItem>
                  <FormItem {...formItemLayout} label='续费金额'>
                    {getFieldDecorator('renewAmount2', {
                      rules: [
                        {
                          required: false,
                          whitespace: true,
                          pattern: /^(\d{0,5}(\.\d{1,2})?)$/,
                          message: '请输入正确的续费金额,只能输入数字',
                        },
                      ],
                    })(<Input placeholder='请输入续费金额' min={0} maxLength={9} suffix='元' />)}
                  </FormItem>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div className='row'>
                  <FormItem {...formItemLayout} label='续费年限' colon={false}>
                    <div>3年</div>
                  </FormItem>
                  <FormItem {...formItemLayout} label='续费金额'>
                    {getFieldDecorator('renewAmount3', {
                      rules: [
                        {
                          required: false,
                          whitespace: true,
                          pattern: /^(\d{0,5}(\.\d{1,2})?)$/,
                          message: '请输入正确的续费金额,只能输入数字',
                        },
                      ],
                    })(<Input placeholder='请输入续费金额' min={0} maxLength={9} suffix='元' />)}
                  </FormItem>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div className='row'>
                  <FormItem {...formItemLayout} label='续费年限' colon={false}>
                    {getFieldDecorator(
                      'renewYear',
                      {},
                    )(
                      <Select style={{ minWidth: 144 }} placeholder='请选择年限' allowClear>
                        {[4, 5, 6, 7, 8, 9, 10].map((v) => (
                          <Option value={v} key={v}>
                            {v}年
                          </Option>
                        ))}
                      </Select>,
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label='续费金额'>
                    {getFieldDecorator('activityRenewAmount', {
                      rules: [
                        {
                          required: false,
                          whitespace: true,
                          pattern: /^(\d{0,5}(\.\d{1,2})?)$/,
                          message: '请输入正确的续费金额,只能输入数字',
                        },
                      ],
                    })(
                      <Input
                        placeholder='请输入续费金额'
                        min={0}
                        disabled={!year?.renewYear}
                        maxLength={9}
                        suffix='元'
                      />,
                    )}
                  </FormItem>
                </div>
              </Col>
            </Row>
          </Card>
          <Card title='商行分类'>
            <Row>
              <Col span={24} style={{ paddingTop: '10px' }}>
                <FormItem label='商行分类' {...formItemLayout}>
                  {getFieldDecorator('pactStatus', {
                    initialValue: pactStatus,
                  })(
                    <Radio.Group onChange={this.onChangePact}>
                      {[...STALL_TYPES].map(([k, v]) => (
                        <Radio value={k} key={k}>
                          {v}
                        </Radio>
                      ))}
                    </Radio.Group>,
                  )}
                </FormItem>
              </Col>
            </Row>
          </Card>
          <FormItem>
            <div className='formSubmit'>
              <Button
                style={{ float: 'left', marginRight: '5px', marginTop: '10px' }}
                type='primary'
                htmlType='button'
                onClick={this.showConfirm.bind(this)}
              >
                保存
              </Button>
              &nbsp;&nbsp;
              <Button
                style={{ float: 'left', marginTop: '10px' }}
                onClick={() => this.toStallMangent()}
              >
                取消
              </Button>
            </div>
          </FormItem>
        </Form>
      </div>
    );

    return (
      <PageHeaderLayout>
        <div className='batchLayout'>
          <Card
            className='listCard'
            bordered={false}
            title=''
            style={{ marginTop: 24 }}
            bodyStyle={{ padding: '0 16px 40px 16px' }}
            extra={extraContent}
          />
        </div>
        <Modal
          title={`提示`}
          visible={this.state.visible}
          onCancel={this.handleCannel}
          footer={null}
          width='560px'
          style={{ marginTop: '10%' }}
        >
          <div style={{ textAlign: 'center', fontSize: '16px' }}>
            <div> {this.state.tooltip || ''}</div>
          </div>
          <div style={{ textAlign: 'center', fontSize: '16px', marginTop: '12px' }}>
            <Button
              type='primary'
              htmlType='submit'
              style={{ marginLeft: '8px' }}
              onClick={this.handleCannel}
            >
              确定
            </Button>
          </div>
        </Modal>
      </PageHeaderLayout>
    );
  }
}
export default NewStallManage;
